import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import RSSIcon from "../assets/rss.svg"
import BlogItem from "../components/BlogItem"
import Layout from "../components/Layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import moment from "moment"

export default function BlogPost({ data, pageContext: { collection } }) {
  const {
    allAirtable: { edges, totalCount },
  } = data

  const tags = new Set()
  for (let edge of edges) {
    const {
      node: { data },
    } = edge
    if (data?.["Tags"]) {
      for (let tag of data["Tags"]) {
        tags.add(tag)
      }
    }
  }

  const tagArray = Array.from(tags)
  tagArray.sort()

  const onRandomBlogClick = () => {
    const randomBlog = edges[Math.floor(Math.random() * edges.length)]
    window.open(randomBlog.node.data["URL"])
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
  }

  return (
    <Layout>
      <div
        css={css`
          text-align: center;
          margin-top: 30px;
          margin-bottom: 30px;
        `}
      >
        <h1
          css={css`
            margin-bottom: 5px;
          `}
        >
          {collection}
        </h1>
        <span
          css={css`
            display: block;
            margin-bottom: 10px;
          `}
        >
          ({totalCount} blogs)
        </span>
        <span
          css={css`
            display: block;
            margin-bottom: 10px;
          `}
        >
          <button
            css={css`
              all: unset;
              color: blue;
              text-decoration: underline;
              cursor: pointer;
            `}
            onClick={onRandomBlogClick}
          >
            Surprise Me!
          </button>
        </span>
      </div>

      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
        `}
      >
        <h2
          css={css`
            text-align: center;
          `}
        >
          Tags
        </h2>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 50px;

            a {
              margin: 4px 10px;
            }

            a:hover {
              transition: none;
            }
          `}
        >
          {tagArray.map(tag => (
            <Link
              css={css`
                font-size: 16px;
                color: #0178bd;
                transition: color 2s linear;

                &:hover {
                  color: ${`rgb(${getRandomInt(0, 255)},
                    ${getRandomInt(0, 255)},
                    ${getRandomInt(0, 255)})
                  })`};
                }
              `}
              to={`/tag/${tag.replace(".", "dot")}`}
            >
              #{tag}
            </Link>
          ))}
        </div>
        <h2
          css={css`
            text-align: center;
            margin-bottom: 10px;
          `}
        >
          Blogs
        </h2>
        {edges.map(edge => {
          const {
            node: { data },
          } = edge
          return <BlogItem data={data} />
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CollectionQuery($collection: String!) {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Last_Post_Timestamp: { ne: null }
          Collection: { eq: $collection }
          dkb_review: { eq: "approved" }
        }
      }
      sort: { fields: data___Last_Post_Timestamp, order: DESC }
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Tags
          }
        }
      }
    }
  }
`
